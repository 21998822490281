<template>
    <div>
<!--        <product-modal :prodSet="prodSet" :dialog="prod_dialog"></product-modal>-->
        <mini-product-modal :prodSet="prodSet" :dialog="prod_dialog"></mini-product-modal>
        <v-card
                style="border-radius:15px"
                flat
                class="mx-auto my-2"
                max-width="165"
                to=""
                @click="showDetails"
        >
            <v-img
                :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=150`"
                :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=10`"
                height="150"
        >
            <template v-slot:placeholder>
                <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </template>
        </v-img>
            <v-card-title class="my-n4 text-center d-block caption font-weight-bold">
                {{prodSet.name}} {{prodSet.weight && prodSet.units?` / ${prodSet.weight} ${prodSet.units}`:``}}<br />
                <i class="caption">{{prodSet.product_type.name}}</i>
                <div class=" subtitle-2 black--text">
                    {{prodSet.price | currency}} <i class="grey--text"></i>
                </div>
            </v-card-title>
            <v-btn
                    absolute
                    color="red"
                    class=" elevation-12 white--text mb-8"
                    small
                    right
                    top
            >
                BUY
                <v-icon small>mdi-cart</v-icon>
            </v-btn>
            <v-card-text class="white">
                <div
                        class="text-center mx-0 caption mt-n5"
                >
                    <p class="grey--text text--darken-3  font-weight-bold">
                        <v-icon small color="orange">mdi-domain</v-icon>
                        {{prodSet.company.name}}
                    </p>
                    <i class="grey--text text--darken-3 caption">
                        <v-icon small color="red">mdi-map-marker</v-icon>
                        {{prodSet.company.location}}
                    </i>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {eventBus} from "@/main";
    import MiniProductModal from "./MiniProductModal";
    export default {
        name: "product_card",
        props:[
            'product_details'
        ],
        data: () => ({
            loading: false,
            selection: 1,
            prod_dialog:false,
        }),
        computed:{
             prodSet(){
                 return this.product_details;
             },
        },
        mounted(){
            eventBus.$on('product_modal',e=>{
                this.prod_dialog = e;
            })
        },
        methods: {
            showDetails () {
                this.prod_dialog = true;
            },
        },

        components:{
            MiniProductModal,
        }
    }
</script>

<style scoped>
    .product_card{
        border:1px solid #e3e3e3;
        border-radius:10px 10px 10px 20px;
    }

</style>
