<template>
    <div>
        <v-dialog :width="isSmallScreen ? '310px': '600px'" v-model="minimumAlert">
            <v-card :height="isSmallScreen ?'220px' : '200px'" :width="isSmallScreen? '310px':'600px'" rounded="xl">
                <div class="d-flex justify-end">
                    <v-icon @click="minimumAlert = false" class="mt-1 mr-2" color="red">mdi-close</v-icon>
                </div>
                <v-card-title><v-icon class="mr-1" color="warning">mdi-bell-ring</v-icon>Minimum Quantity Alert</v-card-title>
                <v-card-text :style="{fontSize: isSmallScreen? '12px' : '16px'}">Minimum quantity for selected product is {{prodSet.minimum ? prodSet.minimum : 1}}. Do you still want to add to cart?</v-card-text>
                <v-row class="mx-2" dense>
                    <v-col>
                        <v-btn :style="{textTransform: 'lowercase', color: '#064316'}" plain @click="addCart(); minimumAlert = false">yes</v-btn>
                    </v-col>
                    <v-col align="end">
                        <v-btn :style="{textTransform: 'lowercase', color: 'red'}" plain @click="minimumAlert = false">no</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <new-product-modal :prodSet="prodSet" :dialog="prod_dialog"></new-product-modal>
        <v-card
            :style="[{borderRadius: isMobile? '20px': '20px'}]"
            flat
            :class="isMobile? 'px-0 mx-0 my-2 pt-2' : 'px-0 mx-0 my-2 pt-2'"
            :width="isMobile?195:300"
            to=""
            :height="isMobile? '160px' :'260px'"
            @click="showDetails"
        >
            <div style="position: relative">
                <v-img
                :style="[{position: 'relative', borderRadius: isMobile? '17px' : '20px'}]"
                :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=400`"
                :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=10`"
                :height="isMobile?'98px':'170px'"
                class="mx-2"
                >
                <template v-slot:placeholder>
                    <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>

                </v-img>
                <v-btn
                    @click.stop="showMinimumDialog"
                    v-if="isMobile"
                    color="green darken-2"
                    fab
                    x-small
                    elevation="0"
                    outlined
                    style="border: white 2px solid;background-color: green;"
                    absolute
                    bottom
                    right
                    >
                        <v-icon color="white">mdi-cart-outline</v-icon>
                </v-btn>
                <v-btn
                    @click.stop="showMinimumDialog"
                    v-else
                    color="green darken-2"
                    fab
                    small
                    elevation="0"
                    outlined
                    style="border: white 2px solid;background-color: green;"
                    absolute
                    bottom
                    right
                    >
                        <v-icon color="white">mdi-cart-outline</v-icon>
                </v-btn>
            </div>

            <div :style="[{ overflow: 'hidden', fontSize: isMobile? '10px' : '15px', fontWeight:'400', textAlign: 'start', marginLeft: '11px', marginTop: isMobile? '12px':'16px'}]" :class=" isMobile? 'text-truncate':'text-truncate'">
                {{prodSet.name}} {{prodSet.weight && prodSet.units?` / ${prodSet.weight} ${prodSet.units}`:``}}<br />
                <p class="font-weight-bold">{{ prodSet.name }}</p>
            </div>


            <v-row align="start" style="display: flex; justify-content: space-evenly">
                <v-col align="start" md="5" sm="6" cols="5">
                    <div :class="isMobile? 'mt-n2' : 'grey&#45;&#45;text text&#45;&#45;darken-3'"
                        style="margin-left: 7px; height:22px; text-align: start; align-items: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                    >
                        <v-icon :class="isMobile? 'mt-1': null" :size="isMobile? 10 : 18" color="green darken-2">mdi-map-marker</v-icon>
                        <span :style="[isMobile? {paddingLeft: '2px', textTransform: 'capitalize', fontSize: '8px', fontWeight: '600'}: {paddingLeft: '2px', textTransform: 'capitalize', fontSize: '14px', fontWeight: '600'} ]" class="grey--text">{{prodSet.company.location}}</span>
                    </div>
                </v-col>
                <v-col sm="6" md="7" cols="7" align="end">
                    <span :class="isMobile? 'green--text text--darken-3 font-weight-bold mr-2':'green--text text--darken-3 font-weight-bold mr-2'">
                        <p class="mr-2" :style="[{whiteSpace:'nowrap', fontSize: isMobile? '10px' : '19px', fontWeight: 700}]">{{prodSet.price | currency}}</p>
                    </span>
                </v-col>
            </v-row>

        </v-card>
    </div>
</template>

<script>
    import {eventBus} from "@/main";
    import NewProductModal from "./NewProductModal";
    export default {
        name: "new_product_card",
        props:[
            'product_details',
        ],
        data: () => ({
            minimumAlert: false,
            loading: false,
            selection: 1,
            prod_dialog:false,
            close: false
        }),
        computed:{
             prodSet(){
                return this.product_details;
             },
             computedPrice() {
                return this.quantity ? parseFloat(this.quantity) * parseFloat(this.prodSet.price) : 0;
            },
        },
        mounted(){
            eventBus.$on('product_modal',e=>{
                this.prod_dialog = e;
            })
            console.log(this.prod_details, "----------I am printing selected prod_details")
        },
        methods: {
            showDetails () {
                this.prod_dialog = true;
            },

            showMinimumDialog(){
                if(Number(this.prodSet.minimum) === 1 || this.prodSet.minimum === null){
                    this.addCart()
                }
                else{
                    this.minimumAlert = true
                }
            },

            addCart() {
                this.prod_dialog = false;
                this.quantity = this.prodSet.minimum ?? 1
                let prodUp = {
                    ...this.prodSet,
                    purchase_quantity: this.quantity,
                    subtotal: this.computedPrice,
                    // lone_item: true
                };
                eventBus.$emit("cart_add", true);
                this.$store.commit("setCartMenu", true);
                this.$store.commit("setSnack", {
                    color: "green",
                    status_msg: "Success!",
                    added_msg: "Product Added to cart.",
                });
                this.$store.commit("setCart", prodUp);
                }
        },

        components:{
            NewProductModal,
        }
    }
</script>

<style scoped>
    .product_card{
        border:1px solid #e3e3e3;
        border-radius:10px 10px 10px 20px;
    }

    .fb-btn.v-btn--outlined {
        border: thin solid #CCCCCC;
    }

</style>
