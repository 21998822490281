<template>
  <!-- <div class="text-center"> -->
    <!-- <v-overlay v-show="modal_dialog" style="background-color:#000000; opacity: 0.75;" >
      <v-dialog persistent hide-overlay :fullscreen="$vuetify.breakpoint.xsOnly" v-model="modal_dialog"> -->
      <v-card rounded="0" style="height: 100vh; width: 100vw" v-if="isSmallScreen">
        <div style="position: absolute; top: 10px; left: 30%" class=""><img src="@/assets/imgs/ghana_tag.jpg" style="width: 200px;"/></div>
        <v-btn style="position: absolute; right: 0" icon @click="closeup" class="my-2">
            <v-icon class="red--text lighten-1">mdi-close</v-icon>
        </v-btn>
         <div :class="isMobile? 'col-sm-12 mr-4 mb-4 px-8' : 'col-sm-6 mr-0 px-4'">
            <v-img
              class="mt-10"
              style="border-radius: 10px"
              cover
              max-width='500'
              max-height="350px"
              :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=400`"
              :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=10`"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <div >
              <div class="d-flex mt-3 justify-space-between align-center">
                <div class="align-center">
                  <p style="text-transform: capitalize; font-size: 20px; font-weight: 600; line-height: 22px" class="black--text mb-0">{{ prodSet.name }}</p>
                  <p style="font-size: small;" class="mb-0">{{prodSet.name.includes('Full Chicken') ? ' ' : prodSet.weight}} {{prodSet.name.includes('Full Chicken') ? ' ' : prodSet.units}}</p>
                </div>
                
              <div class="d-flex flex-wrap" style="align-items:baseline">
              <!-- <p class="primary--text mb-0" style="font-size: 22px; font-weight: 600">{{ prodSet.price | currency}}</p> -->
              <p class="primary--text mb-0" style="font-size: 22px; font-weight: 600">{{ prodSet.price | currency}}</p>
              
             </div>
             
            </div>

              <div class="in-para">
               <!-- <p>{{ prodSet.company.name }}</p> -->
               <p>{{ prodSet.company.name }}</p>
               <v-icon class="ml-2" size="14">mdi-map-marker</v-icon>
               <!-- <p>{{ prodSet.company.location }}</p> -->
               <p>{{ prodSet.company.location }}</p>
             </div>
            
            <p
              class="pa-1 mb-0"
              style="font-size: 16px; border-bottom: 1px solid #f0f0f0;"
              >
                Minimum Order Quantity:
                <b class="">{{ prodSet.minimum }}{{ prodSet.product_type.name === 'Maize' || prodSet.product_type.name === 'Soya' || prodSet.product_type.name.includes('Shea') ? 'MT' : ''}}</b>
              </p>
            <!-- <table v-show="prodSet.usage || prodSet.weight" class="details_table my-2"> -->
              <table v-show="prodSet.weight" class="details_table my-2">
              <tr>
                <td class="black--text ml-2">
                  <!-- <p style="font-size: 12px" class="mb-0">Usage: <b>{{ prodSet.usage ? prodSet.usage : 'n/a'}}</b></p> -->
                </td>
                <td class="black--text ml-2"></td>
              </tr>
              <tr>
                <td class="black--text ml-2">
                  <p style="font-size: 12px" class="mb-0">Weight: <b>{{ prodSet.weight? prodSet.weight : 'n/a' }}{{ prodSet.weight? prodSet.units : '' }}</b></p>
                </td>
                <td class="black--text ml-4"></td>
              </tr>
              <tr>
                <!--<td class="black&#45;&#45;text ml-2">Packaging Type: </td>-->
              </tr>
            </table>
            <div class="price_single">
              <p
              class="pa-1 mb-0"
              style="font-size: 16px; border-top: 1px solid #f0f0f0;"
              >
                Choose Order Quantity
              </p>
              <v-layout justify-start class="mr-1">
                <v-flex sm3 align-self-center>
                  <v-text-field
                    rounded
                    dense
                    hint="Quantity"
                    persistent-hint
                    v-model="quantity"
                    type="number"
                    aria-valuemin="1"
                    step="1"
                    background-color="back"
                    color="grey"
                  >
                    <template v-slot:append:increment>
                      <v-icon>mdi-chevron-up</v-icon>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex sm3 class="ml-3">
                  <v-text-field
                    disabled
                    color="red"
                    rounded
                    background-color="back"
                    dense
                    :hint="`Price(${currency})`"
                    persistent-hint
                    v-model="prodSet.price"
                    class="pl-n10"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex sm4 class="ml-3">
                  <v-text-field
                    disabled
                    color="red"
                    rounded
                    dense
                    background-color="back"
                    :hint="`Subtotal(${currency})`"
                    persistent-hint
                    v-model="computedPrice"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <v-layout justify-center row class="mt-6">
                <div class="headline" v-if="showPhone">
                <!-- <v-btn width="300px" outlined rounded class="primary--text darken-2 mb-3" dark @click="callVendor(prodSet.company.phone)"> -->
                  <v-btn width="300px" outlined rounded class="primary--text darken-2 mb-3" dark @click="callVendor(prodSet.weight)">
                  <v-icon color="primary" left>mdi-phone-in-talk</v-icon>
                  <!-- {{ prodSet.company.phone }} --> 
                </v-btn>
              </div>
<!--                <div class="d-flex justify-center" v-if="!showPhone">-->
<!--                  <v-btn-->
<!--                      elevation="0"-->
<!--                      class="primary mb-3"-->
<!--                      @click="displayPhoneNumber"-->
<!--                      dark-->
<!--                      rounded-->
<!--                      width="300px"-->
<!--                  >-->
<!--                    <v-icon color="white" left>mdi-phone-in-talk</v-icon>-->
<!--                      <p class="mb-0 pr-0" :style="{textTransform: 'capitalize', fontWeight: '400', fontSize: isMobile? '14px' : '18px'}">Show Seller Contact</p>-->
<!--                  </v-btn>-->
<!--                </div>-->
              </v-layout>
            </div>
          </div>

            <div class="mt-10" style="text-align:center"> 
              <v-layout justify-center wrap >
                <v-flex sm6>
                  <v-btn color="primary" outlined @click="addCart" class="px-5 py-5"
                    ><p class="mb-0" :style="{textTransform: 'capitalize', fontWeight: 400, fontSize: isMobile? '12px' : '16px'}">Add to Cart</p><v-icon right>mdi-cart-outline</v-icon></v-btn
                  >
                </v-flex>
                <v-flex sm6 md12>
                  <v-btn elevation="0" color="primary" @click="goCheckout" class="px-6 py-5"
                    ><p class="mb-0"  :style="{textTransform: 'capitalize', fontWeight: 400, fontSize: isMobile? '12px' : '16px'}">Checkout</p><v-icon right>mdi-cart-arrow-right</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </div>
          </div>
      </v-card>
      <v-card :width="isMobile? '95vw' :'70vw'" v-else rounded color="white"> 
        <div style="position: absolute; top: 10px; left: 40%" class=""><img src="@/assets/imgs/ghana_tag.jpg" style="width: 200px;"/></div>
          <v-btn style="position: absolute; right: 0" icon @click="closeup" class="ma-4">
            <v-icon class="red--text lighten-1 lg">mdi-close</v-icon>
          </v-btn>
        <div
          class="px-4 pb-10 d-flex justify-center align-center flex-grow"
          :style="{ width:'100%', height: '90%', }"
        >
          <div class="align-center col-sm-6 mx-2 px-9">
            <p style="text-transform: capitalize; font-size: 30px; font-weight: 600; line-height: 40px" class="black--text mb-0">{{ prodSet.name }}</p>
            <div class="d-flex flex-wrap" style="align-items:baseline">
              <p class="primary--text mb-0" style="font-size: 40px; font-weight: 600">{{ prodSet.price | currency}}</p>
              <span style="font-size: 16px; font-weight: 500;">
                 {{
                prodSet.weight && prodSet.units
                  ? prodSet.name.includes('Full Chicken') ? ' ' : ` per ${prodSet.weight} ${prodSet.units}`
                  : ``
                }}
              </span>
             </div>
             <div class="in-para">
                <p>{{ prodSet.company.name }}</p>
               <v-icon class="ml-2" size="14">mdi-map-marker</v-icon>
                <p>{{ prodSet.company.location }}</p>
             </div>
            <p
              class="pa-1 mb-0"
              style="font-size: 16px; border-bottom: 1px solid #f0f0f0;"
              >
                Minimum Order Quantity:
              <b class="">{{ prodSet.minimum }} {{ this.minimumMaizeStatus || this.minimumSoyaStatus || this.prodSet.name === 'Shea Nuts' ? 'Metric Tons' : ''}}</b>
              </p>
            <!-- <table v-show="prodSet.usage || prodSet.weight" class="details_table my-2">
              <tr>
                <td class="black--text ml-2">
                  <p style="font-size: 12px" class="mb-0">Usage: <b>{{ prodSet.usage ? prodSet.usage : 'n/a'}}</b></p>
                </td>
                <td class="black--text ml-2"></td>
              </tr>
              <tr>
                <td class="black--text ml-2">
                  <p style="font-size: 12px" class="mb-0">Weight: <b>{{ prodSet.weight? prodSet.weight : 'n/a' }}{{ prodSet.weight? prodSet.units : '' }}</b></p>
                </td>
                <td class="black--text ml-4"></td>
              </tr>
              <tr>
                                    <td class="black&#45;&#45;text ml-2">Packaging Type: </td>
              </tr>
            </table> -->
            <div class="price_single">
              <p
              class="pa-1 mb-0"
              style="font-size: 16px; border-top: 1px solid #f0f0f0;"
              >
                Choose Order Quantity
              </p>
              <v-layout justify-start class="mr-1">
                <v-flex sm3 align-self-center>
                  <v-text-field
                    rounded
                    dense
                    hint="Quantity"
                    persistent-hint
                    v-model="quantity"
                    type="number"
                    aria-valuemin="1"
                    step="1"
                    min="1"
                    background-color="back"
                    color="grey"
                  >
                    <template v-slot:append:increment>
                      <v-icon>mdi-chevron-up</v-icon>
                    </template>
                  </v-text-field>
                </v-flex>
                <v-flex sm3 class="ml-3">
                  <v-text-field
                    disabled
                    color="red"
                    rounded
                    background-color="back"
                    dense
                    :hint="`Price(${currency})`"
                    persistent-hint
                    v-model="prodSet.price"
                    class="pl-n10"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex sm4 class="ml-3">
                  <v-text-field
                    disabled
                    color="red"
                    rounded
                    dense
                    background-color="back"
                    :hint="`Subtotal(${currency})`"
                    persistent-hint
                    v-model="computedPrice"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <!-- <v-layout justify-start row class="mt-8">
                <div class="headline" v-if="showPhone">
                <v-btn style="font-size:22px" width="300px" outlined rounded class="primary--text darken-2 mb-3" dark @click="callVendor(prodSet.company.phone)">
                  <v-icon size="30" color="primary" left>mdi-phone-in-talk</v-icon>
                  {{ prodSet.company.phone }}
                </v-btn>
              </div>
                <div class="d-flex justify-center" v-if="!showPhone">
                  <v-btn
                      elevation="0"
                      class="primary mb-3"
                      @click="displayPhoneNumber"
                      dark
                      width="300px"
                  >
                    <v-icon color="white" left>mdi-phone-in-talk</v-icon>
                      <p class="mb-0 pr-0" :style="{textTransform: 'capitalize', fontWeight: '400', fontSize: isMobile? '14px' : '16px'}">Show Seller Contact</p>
                  </v-btn>
                </div>
              </v-layout> -->
            </div>
          </div>

          <div class="col-sm-6 mr-6 px-4 d-grid">
            <v-img
              style="border-radius: 10px"
              max-width='500'
              :max-height="$vuetify.breakpoint.xsOnly? 200 : 500"
              :src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=400`"
              :lazy-src="`${imgHost}imgs/products/${prodSet.product_type.slug}/${prodSet.img_url}?w=10`"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="mt-8" style="text-align:center"> 
              <v-layout justify-center wrap >
                <v-flex sm6>
                  <v-btn color="primary" outlined @click="addCart" class="px-8 py-5"
                    ><p class="mb-0" :style="{textTransform: 'capitalize', fontWeight: 400, fontSize: isMobile? '12px' : '16px'}">Add to Cart</p><v-icon right>mdi-cart-outline</v-icon></v-btn
                  >
                </v-flex>
                <v-flex sm6>
                  <v-btn elevation="0" color="primary" @click="goCheckout" class="px-8 py-5"
                    ><p class="mb-0"  :style="{textTransform: 'capitalize', fontWeight: 400, fontSize: isMobile? '12px' : '16px'}">Checkout</p><v-icon right>mdi-cart-arrow-right</v-icon></v-btn
                  >
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </v-card>
    <!-- </v-dialog>
    </v-overlay> -->
    
  <!-- </div> -->
</template>

<script>
import { eventBus } from "../../../main";
import mixpanel from "mixpanel-browser";

mixpanel.init("6a7537bf8311e4fc76404e3362906a58", { debug: true, track_pageview: true });

export default {
  props: ["prodSet", "dialog"],
  name: "product_modal",
  data() {
    return {
      showPhone: false,
      quantity: 1,
    };
  },
  computed: {
    modal_dialog() {
      return this.dialog;
    },
    computedPrice() {
      return this.quantity ? parseFloat(this.quantity) * parseFloat(this.prodSet.price) : 0;
    },
    minimumSoyaStatus() {
      return this.prodSet.name === 'Cleaned Soya' || this.prodSet.name === 'Uncleaned Soya'
    },
    minimumMaizeStatus() {
      return this.prodSet.name === 'Yellow Maize' || this.prodSet.name === 'White Maize'
    }
  },
  mounted(){
    console.log(this.prodSet, this.dialog, "----------I am printing from New Modal")
  },
  methods: {
    displayPhoneNumber() {
      this.showPhone = true;

      // mixpanel.track("Show Number", {
      //   companyName: this.prodSet.company.name,
      //   companyPhone: this.prodSet.company.phone,
      //   productId: this.prodSet.id,
      //   productName: this.prodSet.name,
      //   productType: this.prodSet.product_type.name,
      //   productCategory: this.prodSet.category.name,
      // });
    },
    callVendor (phone){
      // mixpanel.track("Call Vendor", {
      //   companyName: this.prodSet.company.name,
      //   companyPhone: this.prodSet.company.phone,
      //   productId: this.prodSet.id,
      //   productName: this.prodSet.name,
      //   productType: this.prodSet.product_type.name,
      //   productCategory: this.prodSet.category.name,
      // });
      window.location.href = `tel://${phone}`
    },
    addCart() {
      if(this.minimumSoyaStatus || this.minimumMaizeStatus) {
        let min = (Number(this.prodSet.minimum) * 1000) / (Number(this.prodSet.weight ?? 0) )
        if( Number(this.quantity) < min ){
          this.$store.commit(
              "setSnack",
              {
                color: "#FB607F",
                status_msg: "Minimum Quantity",
                added_msg: `You Cannot Order Less Than ${this.prodSet.minimum} Metric Tons/ ${min} bags`
              }
          )
        }
        else{
          this.pushToCart()
        }
      }
      else{
        if(Number(this.quantity) < this.prodSet.minimum) {
            this.$store.commit(
                "setSnack",
                {
                  color: "#FB607F",
                  status_msg: "Minimum Quantity",
                  added_msg: `You Cannot Order Quantity Less Than ${this.prodSet.minimum}`
                }
            )
        }
        else{
          this.pushToCart()
        }
      }
    },
    pushToCart(){
      mixpanel.track("Add to Cart Clicks", {
        product: `${this.prodSet.name}_added_to_cart`,
        quantity: `${this.quantity}_added_to_cart`
      })
      let prodUp = {
        ...this.prodSet,
        purchase_quantity: this.quantity,
        subtotal: this.computedPrice,
      };
      eventBus.$emit("cart_add", true);
      this.$store.commit("setCartMenu", true);
      this.$store.commit("setSnack", {
        color: "green",
        status_msg: "Success!",
        added_msg: "Product Added to cart.",
      });
      this.$store.commit("setCart", prodUp);
      this.closeup()
    },

    pushToCheckout(){
      mixpanel.track("Checkout Clicks", {
        product: `${this.prodSet.name}_Checkout`,
        quantity: `${this.quantity}_Checkout`
      })
      let prodUp = {
        ...this.prodSet,
        purchase_quantity: this.quantity,
        subtotal: this.computedPrice,
      };
      this.$store.commit("setCartMenu", true);
      this.$store.commit("setCart", prodUp);
      this.$router.push("/cart");
    },

    goCheckout() {
      if(this.minimumSoyaStatus || this.minimumMaizeStatus) {
        let min = (Number(this.prodSet.minimum) * 1000) / (Number(this.prodSet.weight ?? 0) )
        if( Number(this.quantity) < min ){
          this.$store.commit(
              "setSnack",
              {
                color: "#FB607F",
                status_msg: "Minimum Quantity",
                added_msg: `You Cannot Order Less Than ${this.prodSet.minimum} Metric Tons/ ${min} bags`
              }
          )
        }
        else{
          this.pushToCheckout()
        }
      }
      else{
        if(Number(this.quantity) < this.prodSet.minimum) {
          this.$store.commit(
              "setSnack",
              {
                color: "#FB607F",
                status_msg: "Minimum Quantity",
                added_msg: `You Cannot Order Quantity Less Than ${this.prodSet.minimum}`
              }
          )
        }
        else{
          this.pushToCheckout()
        }
      }
    },
    closeup() {
      this.$emit("product_modal", false);
    },
  },
};
</script>

<style scoped lang="scss">
.in-para {
  display: flex;
  color: #b3b3b3;
  font-size: 0.8em;
  line-height: 1em;
  margin: 0.5em 0;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 0.5em 0;
  p {
    margin-bottom: 0;
  }
}
.price_single {
  padding-bottom: 0.3em;
}
span.reducedfrom {
  color: #fab005;
  font-size: 1.5em;
}

h4.quick {
  color: #000;
  font-size: 1.1em;
  text-transform: uppercase;
  margin-top: 0.2em;
}
p.quick_desc {
  color: #b3b3b3;
  font-size: 0.9em;
  line-height: 1.4em;
  margin: 0.4em 0 1em;
}

/* Shutter In Vertical */
.my-cart-btn1 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  background-color: #fab005;
  color: #fff;
  padding: 0.5em 1em;
  font-size: 1em;
  text-decoration: none;
  border: none;
}
.my-cart-btn1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #029241;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border: none;
}
.my-cart-btn1:hover:before,
.my-cart-btn1:focus:before,
.my-cart-btn1:active:before {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  text-decoration: none;
}

ul.product-qty {
  padding: 0;
  list-style: none;
}
.product-qty span {
  color: #000;
  font-size: 1.2em;
  padding-bottom: 0.5em;
  display: block;
  text-transform: uppercase;
}
.product-qty select {
  border: 1px solid #eee;
  padding: 0.5em;
  font-size: 1em;
  outline: none;
}
.product-qty option {
  border: 1px solid #eee;
}
.wish-list {
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}
.wish-list ul {
  padding: 0;
  list-style: none;
}
.wish-list li {
  display: inline-block;
  margin-right: 4%;
  width: 36%;
}
.wish-list li a i {
  margin-right: 4%;
}
.wish-list li a {
  color: #fab005;
  font-size: 0.85em;
  text-decoration: underline;
}
.wish-list li a span {
  padding-right: 10px;
}
.wish-list li a:hover {
  text-decoration: none;
}

.size-top {
  margin: 2em 0;
}
</style>
